<template>
  <div>
    <el-row>
      <el-col :span="24" style="text-align: center">
        <a :class="textBtnClass('edit')" @click="textBtnClick($event)" data-type="edit">方案备注</a>
        <a :class="textBtnClass('history')" @click="textBtnClick($event)" data-type="history">历史记录</a>
      </el-col>
    </el-row>
    <el-row class="padding-style">
      <el-col :span="24" class="update-tab">
        <div class="multimedia-input" v-show="btnActive === 'edit'">
          <areatextComponent class="multimedia-text" id="textbox" :value.sync="programmeContent" />
        </div>
        <div class="multimedia-history" v-show="btnActive === 'history'">
          <div class="multimedia-history-text multimedia-text">
            <el-scrollbar style="height: 100%">
              <el-row>
                <el-col :span="24" class="title">
                  <span>{{ planName }}</span>
                </el-col>
              </el-row>
              <el-row v-for="(item, index) in recordsList" :key="index">
                <el-col :span="24" class="time">
                  <span>{{ item.sendTime }}</span>
                </el-col>
                <el-col :span="24" class="content">
                  <pre class="text-style">{{ item.msg }}</pre>
                </el-col>
              </el-row>
            </el-scrollbar>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="update-box">
      <el-col :span="24">
        <el-button type="primary" size="small" class="update-btn" v-show="btnActive === 'edit'" @click="sendProgramme"
          >发送方案备注</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
import areatextComponent from '@/views/common/zlkj/textarea';
export default {
  name: 'rightMenuUpdate',
  components: {
    areatextComponent
  },
  props: {
    planName: {
      type: String
    }
  },
  data() {
    return {
      btnActive: 'edit',
      programmeContent: '',
      recordsList: [
        // {
        //   id: 1,
        //   sendTime: '2021.08.28 11:08',
        //   msg: '大牙拔掉',
        //   fileUrl: ''
        // },
        // {
        //   id: 2,
        //   sendTime: '2021.08.28 11:09',
        //   msg: '小牙拔掉',
        //   fileUrl: ''
        // },
        // {
        //   id: 3,
        //   sendTime: '2021.08.28 11:10',
        //   msg: '牙全拔掉',
        //   fileUrl: ''
        // },
        // {
        //   id: 4,
        //   sendTime: '2021.08.28 11:11',
        //   msg: '镶假牙',
        //   fileUrl: ''
        // }
      ]
    };
  },
  computed: {
    planId() {
      return this.$store.state.planId;
    },
    /**
     * 计算按钮式样
     */
    textBtnClass() {
      let vm = this;
      return (type) => {
        if (type === 'edit') {
          if (vm.btnActive === 'edit') {
            return { 'text-btn': true, 'text-btn-active': true };
          } else {
            return { 'text-btn': true };
          }
        }
        if (type === 'history') {
          if (vm.btnActive === 'history') {
            return { 'text-btn': true, 'text-btn-active': true };
          } else {
            return { 'text-btn': true };
          }
        }
      };
    }
  },
  watch: {
    planId() {
      this.$http({
        url: '/plan/remarks',
        method: 'POST',
        data: {
          caseId: this.$store.state.caseId,
          planId: this.$store.state.planId
        }
      }).then(({ data }) => {
        if (data.code == 200 && JSON.parse(data.data).length !== 0) {
          this.recordsList = JSON.parse(data.data);
        }
      });
    }
  },
  methods: {
    textBtnClick(e) {
      this.btnActive = e.target.getAttribute('data-type');
      this.textBoxFocus();
      if (this.btnActive === 'history') {
        this.$http({
          url: '/plan/remarks',
          method: 'POST',
          data: {
            caseId: this.$store.state.caseId,
            planId: this.$store.state.planId
          }
        }).then(({ data }) => {
          if (data.code == 200 && JSON.parse(data.data).length !== 0) {
            this.recordsList = JSON.parse(data.data);
          }
        });
      }
    },
    /**
     * 让输入框获取焦点
     */
    textBoxFocus() {
      if (this.btnActive === 'edit') {
        this.$nextTick(() => {
          document.getElementById('textbox').focus();
        });
      }
    },
    /**
     * 发送方案备注
     */
    sendProgramme() {
      let vm = this;
      this.$http({
        url: '/plan/addRemark',
        method: 'POST',
        data: {
          caseId: this.$store.state.caseId,
          planId: this.$store.state.planId,
          remark: this.programmeContent
        }
      })
        .then(({ data }) => {
          if (data.code == 200) {
            vm.programmeContent = '';
            this.$message({
              message: '发送成功',
              type: 'success'
            });
          }
        })
        .catch(() => {
          this.$message.error('发送失败');
        });
    }
  },
     mounted() { this.$store.commit('changeCopy',true)
    this.textBoxFocus();
  }
};
</script>
<style lang="less" scoped>
.text-btn {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  margin: 0 20px 5px;
  color: #aaadb1;
  width: 66px;
}
.text-btn-active {
  color: #1a91ff;
  border-bottom: 2px solid #1a91ff;
  line-height: 35px;
}
.update-tab {
  height: calc(100vh - 464px);
  background-color: #2e2f33;
}
.multimedia-input {
  padding: 15px 5px 15px 15px;

  /deep/ textarea {
    background-color: #2e2f33;
    width: 100%;
    border: 0px;
  }
}
.multimedia-history {
  padding: 15px 5px 15px 15px;
  background-color: #2e2f33;
}
.update-box {
  margin-left: 25px;
  height: 68px;
  .update-btn {
    position: relative;
    top: 20px;
  }
}
.multimedia-text {
  outline: none;
  overflow-y: hidden;
  height: calc(100vh - 479px);
  color: #ffffff;
  .text-style {
    white-space:pre-wrap;/*css-3*/ 
    white-space:-moz-pre-wrap;/*Mozilla,since1999*/ 
    white-space:-pre-wrap;/*Opera4-6*/ 
    white-space:-o-pre-wrap;/*Opera7*/ 
    word-wrap:break-word;
  }
  .title {
    padding-bottom: 10px;
    font-size: 18px;
  }
  .time {
    padding-bottom: 5px;
    color: #1175d2;
  }
  .content {
    padding-bottom: 15px;
  }
}
.multimedia-history-text {
  height: calc(100vh - 428px);
}
.padding-style {
  padding: 0px 25px;
}
</style>
