<template>
  <FileUpload
    :input-id="inputId"
    :ref="refId"
    :accept="type"
    v-model="file"
    post-action="/plan/sendChatFile"
    @input-file="inputFile"
    class="upload-btn"
    :disabled="getTool"
  >
    <img :src="icon" />
    <!-- <i :class="icon" v-show="!getTool" /> -->
    <i class="el-icon-loading" v-show="getTool" />
  </FileUpload>
</template>
<script>
import FileUpload from 'vue-upload-component';
export default {
  name: 'uploadComponent',
  components: {
    FileUpload
  },
  props: {
    type: {
      type: String
    },
    icon: {
      type: String
    },
    tool: {
      type: String
    }
  },
  data() {
    return {
      showFileList: false,
      multiple: false,
      file: [],
      id: parseInt(Math.random() * 100)
    };
  },
  computed: {
    inputId() {
      return 'inputFile' + this.id;
    },
    refId() {
      return 'inputFileRef' + this.id;
    },
    getTool() {
      if (this.tool === 'img') {
        return this.$store.state.imgUploadFlag;
      } else {
        return this.$store.state.fileUploadFlag;
      }
    }
  },
  watch: {
    file() {
      console.log(this.file);
      let formData = new FormData();
      let size = this.file[0]?.file?.size;
      if (parseFloat(size / 1021 / 1024) >= 50) {
        this.$message.error('请上传小于50M的文件');
        return;
      }
      formData.append('file', this.file[0].file);
      if (this.tool === 'img') {
        this.$store.commit('startImgUpload');
      } else {
        this.$store.commit('startFileUpload');
      }
      let param = {
        data: formData,
        type: this.tool
      };
      this.$emit('sendFile', param);
    }
  },
  methods: {
    inputFile(newFile) {
      // console.log('newFile', newFile);
    }
  }
};
</script>
<style>
.file-uploads-html5 label {
  cursor: pointer;
  width: 22px !important;
}
</style>