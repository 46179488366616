<template>
  <section class="stepTable">
    <el-scrollbar style="height:100%;">
      <div style="padding: 10px 20px;">
        <el-row>
          <div id="zrTeeth" class="float-left"></div>
          <div class="stepZrender">
            <el-scrollbar ref="scrollbar" style="width:100%">
              <div style="width:100%;padding-bottom: 20px;">
                <div id="zrTable"></div>
              </div>
            </el-scrollbar>
          </div>
        </el-row>
        <el-row class="row-title" style="padding-top: 24px;font-size: 12px;">
          <el-col :span="4">
            <div class="rectangle" style="background: #1175d2;"></div>
            <div class="float-left">主动矫治器</div>
          </el-col>
          <el-col :span="4" style="padding-left: 3px">
            <div class="rectangle" style="background: #b3d1ed;"></div>
            <div class="float-left">被动矫治器</div>
          </el-col>
          <el-col :span="4" style="padding-left: 15px">
            <div class="row-img">
              <el-image :src="img.add"></el-image>
            </div>
            <div class="float-left">粘接附件</div>
          </el-col>
          <el-col :span="4" style="padding-left: 15px">
            <div class="row-img">
              <el-image :src="img.ipr"></el-image>
            </div>
            <div class="float-left">片切</div>
          </el-col>
          <el-col :span="4">
            <div class="row-img">
              <el-image :src="img.remove"></el-image>
            </div>
            <div class="float-left">去除附件</div>
          </el-col>
          <el-col :span="4">
            <div class="row-img">
              <el-image :src="img.not"></el-image>
            </div>
            <div class="float-left">缺失牙</div>
          </el-col>
        </el-row>
        <!-- <el-row class="row-title" style="padding-top: 16px">
          <el-col :span="4">
            <div class="row-img">
              <el-image :src="img.add"></el-image>
            </div>
            <div class="float-left">粘结附件</div>
          </el-col>
          <el-col :span="4">
            <div class="row-img">
              <el-image :src="img.remove"></el-image>
            </div>
            <div class="float-left">去除附件</div>
          </el-col>
          <el-col :span="4">
            <div class="row-img">
              <el-image :src="img.not"></el-image>
            </div>
            <div class="float-left">缺失牙齿</div>
          </el-col>
        </el-row> -->
      </div>
    </el-scrollbar>
  </section>
</template>
<script>
import * as zrender from 'zrender'
window.zrender = zrender
import { GetTeethId,GetIPRData,GetAttachmentRange,GetTeethStepTable } from '@/render/renderES';
let TIMERSTEP = null;
export default {
  name: 'stepTable',
  props: {
    totalstep:{
      type: Array
    },
    planIndext:{
      type: Number
    }
  },
  data() {
    return {
      zrTable: null,// 分布表
      zrTeeth: null,// Y轴
      zrGroup: null,// 当前步
      tableWH:{ // 分布表宽高
        width: null,
        height: null
      },
      config:{
        startX: 10, // X轴开始位置
        intervalX: 17, // X轴间隔 ()
        unitX: 5, // X轴单位（步数：显示 0 5 10 ...）
        startY: 25, // Y轴开始位置
        intervalY: 13, // Y轴间隔
        coordColor: 'white', // 坐标线颜色
        activeColor: '#1175d2', // 坐标线颜色: 主动矫治器
        passiveColor: '#b3d1ed', // 坐标线颜色: 被动矫治器
      },
      COORD_X:{}, // X轴坐标
      COORD_Y:{}, // Y轴坐标
      setpData: { // 坐标轴信息
        x: {},
        y: {}
      },
      img:{
        remove:require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/分步/去除附件.png`),
        add:require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/分步/粘结附件.png`),
        ipr:require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/分步/IPR.png`),// 片切
        not:require(`@/assets/#病例详情#3D方案切图9.9/PC/右侧/分步/缺失牙.png`),
      },
      stepSum: 0, // 总步数
      dataStep: [], // 步数
      dataTeethDown: [], // 上颌
      dataTeethUp: [], // 下颌
      dataTeethDownAll: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28], // 上颌 All
      dataTeethUpAll: [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38], // 下颌 All
      data: null
    };
  },
  async created() {},
     mounted() { this.$store.commit('changeCopy',true)
    let _this = this
    TIMERSTEP = setInterval(() => {
      let loadingdom = document.getElementById('loading');
      if(loadingdom.innerText === ''){
        clearInterval(TIMERSTEP);
        TIMERSTEP = null;
        _this.init(_this)
      }
    }, 100);
  },
  components: {},
  watch:{
    "$store.state.planId": function(val){
      let _this = this
      TIMERSTEP = setInterval(() => {
        let loadingdom = document.getElementById('loading');
        if(loadingdom.innerText === ''){
          clearInterval(TIMERSTEP);
          TIMERSTEP = null;
          _this.init(_this);
          _this.$refs.scrollbar.update();
        }
      }, 100);
    },
    planIndext: function(val){
      this.drawStep(this, val)
      if(val > 30){
        this.$refs.scrollbar.wrap.scrollLeft = (val - 30)*20;
      }else{
        this.$refs.scrollbar.wrap.scrollLeft = 0;
      }
    }
  },
  computed: {},
  methods: {
    init(_this){
      // 获取牙位号
      _this.setTeeth(_this)
      // 步数（X轴）
      _this.setStep(_this)
      // 初始化表格
      _this.initDrawTable(_this)
      // 初始化步数设定
      if(_this.planIndext){
        _this.drawStep(_this, _this.planIndext)
      }
      // 缺失牙
      _this.setNotTeeth(_this)
      // 片切 IPR
      _this.setIPR(_this)
      // 粘结附件 去除附件
      _this.setAttachment(_this)
      // 主动矫治器 被动矫治器
      _this.setAppliance(_this)
    },
    // 获取牙位号
    setTeeth(_this){
      _this.dataTeethDown = [];
      _this.dataTeethUp = [];
      let arrTeeth = GetTeethId();
      arrTeeth.forEach((val) => {
        if(val < 30){
          _this.dataTeethDown.push(val)
        }else{
          _this.dataTeethUp.push(val)
        }
      });
    },
    // 步数
    setStep(_this){
      _this.stepSum = _this.totalstep.length;
      let remainder = _this.stepSum % _this.config.unitX;
      if(remainder !== 0){
        _this.stepSum = _this.stepSum + _this.config.unitX - remainder;
      }
      _this.dataStep = [];
      for (let index = 0; index <= _this.stepSum; index++) {
        _this.dataStep.push(index);
      }
    },
    // 缺失牙
    setNotTeeth(_this){
      let dataTop = _this.bouncer(_this.dataTeethDownAll,_this.dataTeethDown)
      let dataBottom = _this.bouncer(_this.dataTeethUpAll,_this.dataTeethUp)
      dataTop.forEach((toothid) => {
        _this.drawIcon(_this, toothid, 0, _this.img.not)
      });
      dataBottom.forEach((toothid) => {
        _this.drawIcon(_this, toothid, 0, _this.img.not)
      });
    },
    // 片切 IPR
    setIPR(_this){
     let dataTop = GetIPRData(true)
     let dataBottom = GetIPRData(false)
     dataTop.forEach((obj) => {
      //  _this.drawIPR(_this,  obj.toothid1, obj.step, Number(obj.ipr).toFixed(2)+'mm')
       _this.drawIPR(_this,  obj.toothid2, obj.step, Number(obj.ipr).toFixed(2)+'mm')
      });
      dataBottom.forEach((obj) => {
      //  _this.drawIPR(_this,  obj.toothid1, obj.step, Number(obj.ipr).toFixed(2)+'mm')
       _this.drawIPR(_this,  obj.toothid2, obj.step, Number(obj.ipr).toFixed(2)+'mm')
      });
    },
    // 粘结附件 去除附件
    setAttachment(_this){
      let data = GetAttachmentRange()
      data.forEach((obj) => {
        if(obj.length>0){
          _this.drawIcon(_this, obj[0], obj[1], _this.img.add)
          _this.drawIcon(_this, obj[0], obj[2], _this.img.remove)
        }
      });
    },
    // 主动矫治器 被动矫治器
    setAppliance(_this){
      _this.dataTeethDown.forEach((toothid) => {
        _this.setDrawAppliance(_this,GetTeethStepTable(toothid),toothid)
      });
      _this.dataTeethUp.forEach((toothid) => {
        _this.setDrawAppliance(_this,GetTeethStepTable(toothid),toothid)
      });
    },
    setDrawAppliance(_this,data,toothid){
      data.forEach((obj) => {
        // 主动为0，被动为1
        if(obj[2] === 1){
            _this.drawAppliance(_this, toothid, obj[0], obj[1], _this.config.passiveColor)
          }else{
            _this.drawAppliance(_this, toothid, obj[0], obj[1], _this.config.activeColor)
          }
      });
    },
    // 初始化坐标
    initDrawTable(_this){
      const innerHeight = window.innerHeight -104 -80 -60 -90 -_this.config.startY - 30;
      _this.config.intervalY = innerHeight/(_this.dataTeethDownAll.length + _this.dataTeethUpAll.length);
      if (_this.config.intervalY < 13){
        _this.config.intervalY = 13;
      }
      // 计算分布表宽高
      let width =  _this.config.intervalX * (_this.dataStep.length - 1) + _this.config.startX + 10;
      let height = _this.config.intervalY * (_this.dataTeethDownAll.length + _this.dataTeethUpAll.length) + _this.config.startY + 10;
      _this.tableWH ={
        width: width - 10,
        height: height - 10
      };
      // 分布表
      _this.zrTable = zrender.init(document.getElementById('zrTable'),{
        width: width,
        height: height
      });
      // Y轴
       _this.zrTeeth = zrender.init(document.getElementById('zrTeeth'),{
        width: 20,
        height: height
      });
      // 计算 XY轴
      _this.setTableX(_this)
      _this.setTableY(_this)
      // X轴
      _this.drawTableX(_this, _this.setpData.x, _this.tableWH.height)
      // Y轴
      _this.drawTableY(_this, _this.setpData.y, _this.tableWH.width)
      // Y轴坐标
      _this.drawTeethY(_this, _this.setpData.y)
    },
    // 计算：X轴
    setTableX(_this){
      let setpData = []
      _this.dataStep.forEach((val, i) => {
        _this.COORD_X[val] = _this.config.intervalX * i + _this.config.startX
        setpData.push({
          no: (_this.totalstep.length-1) < val ? '': val,
          x: _this.COORD_X[val],
          show: (Number(val) % _this.config.unitX) === 0 ? true:false
        });
      });
      _this.setpData.x = setpData
    },
    // 计算：Y轴
    setTableY(_this){
      let setpData = []
      let line_y = _this.config.startY
      _this.dataTeethDownAll.forEach((val, i) => {
        line_y = _this.config.intervalY * i + _this.config.startY
        _this.COORD_Y[val] = line_y
        setpData.push({
          no: val,
          y: line_y
        });
      });
      let line_y_up = (_this.config.intervalY * 2) + line_y
      _this.dataTeethUpAll.forEach((val, i) => {
        line_y = _this.config.intervalY * i + line_y_up
        _this.COORD_Y[val] = line_y
        setpData.push({
          no: val,
          y: line_y
        });
      });
      _this.setpData.y = setpData
    },
    // 纵线
    drawTableX(_this, data, endY){
      data.forEach((obj, i) => {
        if(obj.show){
          let line = _this.zrLine(obj.x, _this.config.startY, obj.x, endY, _this.config.coordColor)
          let text = _this.zrTextCenter(obj.no, _this.config.coordColor)
          text.attr('position', [obj.x, 5])
          _this.zrTable.add(line)
          _this.zrTable.add(text)
        }
      });
    },
    // 横线
     drawTableY(_this, data, endX){
      let line_y;
      data.forEach((obj, i) => {
        let line = _this.zrLine(_this.config.startX, obj.y, endX, obj.y, _this.config.coordColor)
        _this.zrTable.add(line)
      });
      return line_y
    },
    // 横线 坐标轴
    drawTeethY(_this, data){
      data.forEach((obj, i) => {
        let text = _this.zrText(obj.no, _this.config.coordColor)
        text.attr('position', [0, obj.y - 5])
        _this.zrTeeth.add(text)
      });
    },
    // 主动矫治器 被动矫治器
    drawAppliance(_this, toothid, startStep, endStep, color){
      let start = _this.config.startX + startStep * _this.config.intervalX
      let end = _this.config.startX + endStep * _this.config.intervalX
      _this.zrTable.add(_this.zrLine(start, _this.COORD_Y[toothid], end, _this.COORD_Y[toothid], color))
    },
    // 粘结附件 去除附件 缺失牙齿 
    drawIcon(_this, toothid, step, icon){
      let startX = _this.config.startX + step * _this.config.intervalX
      _this.zrTable.add(_this.zrImage(startX, _this.COORD_Y[toothid], icon))
    },
    // 当前步
    drawStep(_this,val){
      if(_this.zrGroup !== null){
        _this.drawStepRemove(_this)
      }
      _this.zrGroup = new zrender.Group()
      _this.zrGroup.add(_this.zrCircle(_this.COORD_X[val], 10, '#1a91ff'))
      _this.zrGroup.add(_this.zrSector(_this.COORD_X[val], 22, '#1a91ff'))
      let text = _this.zrTextCenter(val,  'white')
      text.attr('position', [_this.COORD_X[val], 5])
      _this.zrGroup.add(text)
      _this.zrGroup.add(_this.zrLine(_this.COORD_X[val], _this.config.startY, _this.COORD_X[val], _this.tableWH.height, '#676b71',8))
      _this.zrTable.add(_this.zrGroup)
    },
    // 移除当前步
    drawStepRemove(_this){
      _this.zrGroup.removeAll()
      _this.zrGroup = null
    },
    // 片切
    drawIPR(_this, toothid, step, val){
      let startX = _this.config.startX + step * _this.config.intervalX
      let img = new zrender.Image({ 
        style: {
          x: startX - 4,
          y: _this.COORD_Y[toothid] - 10,
          image: _this.img.ipr,
          width: 8,
          height: 10
        }
      });
      let text = _this.zrText(val, 'white', 10)
      text.attr('position', [startX + 8, _this.COORD_Y[toothid] - 10])
      _this.zrTable.add(text)
      _this.zrTable.add(img)
    },
    // 线段（zrender）
    zrLine(x1, y1, x2, y2, color, lineWidth){
      return new zrender.Line({
        shape:{
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2
        },
        style: {
          stroke: color,
          lineWidth: lineWidth?lineWidth:1
        }
      });
    },
    // 文字（zrender）
    zrText(val, color, size){
      return new zrender.Text({
        style: {
          text: val,
          fontSize: size?size:12,
          fill: color
        }
      })
    },
    zrTextCenter(val, color, size){
      return new zrender.Text({
        style: {
          text: val,
          fontSize: size?size:12,
          align: 'center',
          fill: color
        }
      })
    },
    // 图片（zrender）
    zrImage(x, y, pic){
      return new zrender.Image({ 
        style: {
          x: x - 4,
          y: y - 6,
          image: pic,
          width: 8,
          height: 12
        }
      })
    },
    // 圆（zrender）
    zrCircle(cx, cy, color){
      return new zrender.Circle({
        shape: {
            cx: cx,
            cy: cy,
            r: 8
        },
        style: {
            stroke: color,
            fill: color
        }
      });
    },
    // 扇形（zrender）
    zrSector(cx, cy, color){
      return new zrender.Sector({
        shape:{
          cx: cx,
          cy: cy,
          r: 10,
          startAngle: Math.PI * 1.275,
          endAngle: Math.PI * 1.725
        },
        style: {
            stroke: color,
            fill: color
        }
      })
    },
    bouncer(arr,arrFliter) {
        var un = new Set(arrFliter);
        return arr.filter(item=>!un.has(item));
    }
  }
};
</script>
<style scoped lang="less">
.stepTable{
  color: white;
  // padding: 10px 20px;
  height: calc(100% - 350px);
}
.stepZrender{
  float: left;
  width: calc(100% - 20px);
}

.float-left{
  float: left;
}
.row-img{
  float: left;
  padding-right:10px;
}
</style>
<style lang="less">
.stepTable{
  .row-title{
    font-family: 'SourceHanSansCN-Regular';

    .el-image__inner{
      vertical-align: middle;
    }

    .rectangle{
      width: 20px;
      height: 4px;
      margin-top: 6px;
      margin-right: 8px;
      float: left;
    }
  }
}
</style>
