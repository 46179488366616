<template>
  <el-dialog
    title="批准方案"
    :visible.sync="dialogVisible"
    width="506px"
    top="30vh"
    :modal="modal"
    :close-on-click-modal="clickModal"
  >
    <div class="bolton-box">
      <el-row style="width: 65%;margin-top: 50px;margin-left: 68px;">
        <el-col :span="24" style="text-align: center">
          <span
            >当前:<span style="color: #1a91ff;margin-left: 5px;margin-right:15px">{{ planName }}</span>
            是否确认批准?</span
          >
        </el-col>
      </el-row>
      <el-row style="width: 64%;margin-top: 112px;margin-left: 68px;">
        <el-col :span="12" style="text-align: center">
          <el-button type="primary" class="button-style" @click="save">确认</el-button>
        </el-col>
        <el-col :span="12" style="text-align: center">
          <el-button class="button-style" @click="cancel">取消</el-button>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'planConfirmModal',
  data() {
    return {
      modal: false, //遮罩层标记
      clickModal: false, //点击空白隐藏标记
      planName: ''
    };
  },
  props: {
    visible: {
      type: Boolean
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(value) {
        return this.$emit('update:visible', value);
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('update:visible', false);
    },
    //批准方案
    save() {
      this.$emit('update:visible', false);
      this.$emit('admitPlan');
    },
    //方案名称
    setPlan() {
      this.planName = '';
    }
  }
};
</script>
<style scoped lang="less">
/deep/ .el-dialog__header {
  background-color: #171719;
  padding: 5px 20px;
  span {
    color: #ffffff;
    // font-size: 16px;
  }
  .el-dialog__headerbtn {
    top: 10px;
    i {
      color: #ffffff;
    }
  }
}
/deep/ .el-dialog__title {
  line-height: 30px;
  font-size: 14px;
}
/deep/ .el-dialog__headerbtn {
  font-size: 20px;
}
/deep/ .el-dialog__body {
  padding: 0px 0px 20px;
  // font-size: 16px;
}
/deep/ .el-button--primary {
  background-color: #1a91ff !important;
  background: #1a91ff !important;
  border-color: #1a91ff !important;
}
.bolton-box {
  color: #333;
  padding: 28px 0px 10px 24px;
  .title {
    padding-bottom: 25px;
  }
}
.button-style {
  padding: 7px 10px;
  width: 88px;
  // font-size: 16px;
}
</style>
