<template>
  <el-header class="header-style" height="40px">
    <img class="lgimg" :src="Compatible.loginImage" />
    <div style="margin-right:22px">
      <span>患者：{{ patientName }}</span>
      <!-- <span style="margin-left: 71px">病例编号：{{ outerNo }}</span> -->
    </div>
  </el-header>
</template>
<script>
import { getQueryString } from '@/util/validate';
export default {
  name: 'pageHeader',
  data() {
    return {
      caseId: '',
      planId: 0,
      token: null,
      patientName: '',
      outerNo: ''
    };
  },
  async created() {
    this.caseId = getQueryString('caseId');
    this.planId = getQueryString('planId') || 0;
    this.token = getQueryString('token') || null;
  },
  async    mounted() { this.$store.commit('changeCopy',true)
    await this.getPatient();
    if (window.location.href.indexOf('easysmile.magicalign.com') != -1) {
      console.warn(document.querySelectorAll('.faviconICON')[0]);
      document.querySelectorAll('.faviconICON')[0].href = require('@/assets/favicon.png');
      document.querySelectorAll('title')[0].innerText = `${this.patientName}- 患者详情`;
    }
  },
  components: {},
  methods: {
    //获取患者信息
    getPatient() {
      return this.$http({
        url: '/plan/patient',
        method: 'POST',
        data: {
          caseId: this.caseId
        }
      }).then(({ data }) => {
        if (data.code == 200) {
          data = JSON.parse(data.data);
          this.patientName = data.patientName;
          this.outerNo = data.outerNo;
        }
      });
    }
  }
};
</script>
<style scoped>
.header-style {
  background-color: #171719;
  color: #BABABA;
  position: fixed;
  width: 100%;
  z-index: 999;
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #454648;
}
/* .lgimg {
  display: block;
  width: 123px;
  height: 20px; 
} */
</style>
